.transactions-wg-container {
    border-radius: 8px;
    padding: 12px;
    background-color: #FFF;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06)
}

.transactions-wg-label {
    min-width: 270px;
    margin: 0;
    color: #586673;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.transactions-total {
    margin: 0;
    color: #113453;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
}

.transactions-type-label {
    margin: 0;
    color: #113453;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 12px;
    margin-bottom: 16px;
}