body {
  margin: 0;
  background-color: #f5f5f6;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

.RaLayout-contentWithSidebar {
  background-color: #F5F5F6;
}

.page-title {
  background-color: #F5F5F6;
  font-weight: 700;
  font-size: 24px;
  padding-left: 40px;
  padding-top: 24px;
  text-transform: capitalize;
}

.RaDatagrid-headerCell {
  font-weight: 400 !important;
  padding-left: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #A0A8AF !important;
  font-size: 12px !important;
  line-height: 16px !important;
}

.RaDatagrid-root .MuiTypography-root {
  color: #586673 !important;
  font-size: 12px !important;
}

.RaDatagrid-rowCell {
  padding: 10px 12px !important;
  color: #586673 !important;
  line-height: 18px !important;
  font-size: 14px !important;
  height: 48px !important;
}

.RaDatagrid-row {
  background-color: #FFFFFF !important;
  border-top: 1px solid #E7E9EB !important;
  border-bottom: none !important;
}

.datagrid-id-col {
  text-align: center !important;
}

.RaDatagrid-root {
  border-radius: 0 !important;
}

.MuiGrid-root.MuiGrid-container {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

.MuiGrid-root.MuiGrid-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}



.MuiPaper-root.MuiPaper-elevation.MuiCard-root.RaList-content {
  border: none !important;
}

.MuiTableRow-root.MuiTableRow-head {
  border-top: 1px solid #E7E9EB !important;
}

.MuiTableRow-hover:hover {
  background-color: #F3F9FC !important;
}

.MuiInputBase-root.MuiFilledInput-root:before {
  border: none !important;
}

.MuiInputBase-root.MuiInputBase-formControl {
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #A0A8AF;
}

.MuiInputBase-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedEnd {
  max-width: 128px;
  font-size: 14px !important;
  padding: 8px 12px !important;
}

.MuiInputBase-root.MuiFilledInput-root:after {
  border-bottom: none !important;
}

.MuiFormControl-root.MuiTextField-root {
  margin-top: 0px !important;
}


.MuiButtonBase-root.MuiToggleButton-root.MuiToggleButton-primary {
  padding: 0px 16px !important;
  color: #586673 !important;
  height: 36px !important;
  background-color: #FFFFFF !important;
  border-color: transparent;
}

.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
  color: #FFFFFF !important;
  background-color: #1782CA !important;
}

.MuiToggleButtonGroup-root {
  border-radius: 8px !important;
}

.mt-16 {
  margin-top: 16px;
}