.timeline-container {
    min-width: 100px;
    margin-top: 32px;
}

.timeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.timeline-content p {
    margin: 0;
}


.timeline-label {
    color: #113453;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
}

.timeline-value {
    color: #A0A8AF;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
}