.accounts-wg-container {
    max-height: 310px;
    border-radius: 8px;
    padding: 12px;
    background-color: #FFF;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06)
}

.accounts-wg-label {
    margin: 0;
    color: #113453;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.accounts-chart-labels {
    display: flex;
    row-gap: 16px;
    padding-left: 32px;
    flex-direction: column;
    justify-content: center;
}

.accounts-chart-container {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    min-width: 350px;
}


.status-wrapper {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    display: inline-flex;
}

.status-inner {
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    display: flex;
}

.status-dot {
    width: 9px;
    height: 9px;
    background: #F59E0B;
    border-radius: 45px;
}

.status-text {
    color: #586673;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
}

.status-number {
    color: #113453;
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    word-wrap: break-word;
}


.customTabs .MuiTabs-flexContainer {
    display: inline-flex;
    align-items: center;
    gap: 12px;
}

.customTab {

    /* Styles for each Tab */
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    min-height: 24px !important;
    text-transform: capitalize !important;
    font-family: 'Inter', sans-serif;
    color: #586673;
    /* Default color */
    padding: 0 !important;
    margin: 0 !important;
    min-width: 0 !important;
    /* Removes the default minWidth */
}

.customTab.Mui-selected {
    color: #1782CA;
}


.customTabs .MuiTabs-flexContainer .MuiButtonBase-root {
    border-bottom: 1px solid #A3A8AE;
}

.customTabs .MuiTabs-fixed {
    height: 26px !important;
    max-height: 26px !important;
}