.MuiDrawer-docked {
    background-color: #E2E2E2;
}

.MuiAppBar-root {
    z-index: 1 !important;
    box-shadow: none !important;
    background-color: #E2E2E2 !important;
}

.MuiTypography-root span {
    color: #2c2c2c;
}

.layout {
    background-color: #E2E2E2 !important;
}

#main-content {
    border-radius: 8px;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}


.app-pages#main-content {
    box-shadow: 0px 2px 6px 0px #1018280F;
}


.MuiSvgIcon-root {
    color: #2c2c2c;
}

.RaUserMenu-userButton {
    color: #586673 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.grid-edit-button {
    color: #586673 !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
}

.toolbar-add-button {
    color: #FFF !important;
    padding: 10px 12px !important;
    text-transform: capitalize !important;
    background-color: #1782CA !important;
}

.toolbar-export-button {
    text-transform: capitalize !important;
    padding: 10px 12px !important;
    color: #113453 !important;
    background-color: #F1F1F1 !important;
}

.MuiToolbar-root.MuiToolbar-regular.RaList-actions {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #FFF;
    padding-right: 12px !important;
}

.RaList-actions>.MuiToolbar-root {
    display: flex !important;
    align-items: center !important;
}