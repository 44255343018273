.container {
    background: #f5f5f6;
}

.login-nav {
    padding: 24px;
    padding-left: 40px;
}

.login-bg {
    display: flex;
    margin: 24px;
    border-radius: 32px;
    overflow: hidden;
    background: linear-gradient(102.88deg,
            rgba(17, 50, 99, 1) 0%,
            rgba(2, 17, 35, 1) 100%),
        linear-gradient(to left, #feca0a, #feca0a);
}

.login-bg-img {
    height: calc(100vh - 48px);
}

.form-container {
    display: flex;
    flex-direction: column;
    padding-left: 80px;
    padding-right: 56px;
    padding-top: 120px;
}

.form-title {
    color: #113453;
    font-family: "Inter", sans-serif;
    font-size: 36px;
    line-height: 44px;
    font-weight: 800;
}

.form-subtitle {
    color: #586673;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 40px;
}

.form-label {
    color: '#113453';
    font-size: 14px;
    font-family: "Inter";
    font-weight: 500;
    line-height: 16px;
}