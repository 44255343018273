.wg-transaction-type {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    margin-bottom: 16px;
}

.wg-transaction-type-icon {
    margin: 10px;
    width: 16px;
    height: 16px;
    background: #1782CA;
    border-radius: 50%;
}

.wg-transaction-type-text {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: #171717;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    line-height: 20px;
}

.wg-transaction-type-status {
    display: flex;
    margin-top: 8px;
    height: 8px;
    border-radius: 8px;
    background: #F3F9FC;
}

.wg-transaction-type-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.wg-transaction-type-percent {
    width: 35%;
    background: #1782CA;
    border-radius: 8px
}