.statistic-item {
    flex: 1;
    column-gap: 24px;
    display: flex;
    flex-direction: row;
    padding: 24px;
    border-radius: 8px;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
}

.statistics-row {
    display: flex;
    margin-top: 16px;
    column-gap: 16px;
    flex-direction: row;
}

.statistic-label {
    margin: 0;
    color: #737373;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.statistic-value {
    color: #171717;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
}