.app-bar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0px 2px 6px 0px #1018280F;
}


.app-bar-item {
    color: #586673 !important;
    text-transform: capitalize !important;
    display: flex !important;
    flex-direction: row !important;
    column-gap: 8px;
    padding-left: 16px;
    padding-right: 16px;
    max-height: 48px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 48px !important;
    min-height: 48px !important;
}

.RaLayout-appFrame {
    margin-top: 0 !important;
}