/* SidePanel.css */

.side-panel {
  top: 0;
  right: 0;
  position: fixed;
  height: calc(100%);
  background-color: #fcfcfe;
  overflow-x: hidden;
  transition: 0.5s;
  /* border-radius: 25px; */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 3;
  /* margin-top: 74px; */
  margin-right: 0;
  width: 0;
}

.side-panel-container {
  padding: 24px;
}

.side-panel.open {
  width: 50%;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  z-index: 2;
}

.overlay.display {
  display: block;
}

.side-panel-title {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.side-panel-close-btn {
  cursor: pointer;
}

ul.side-panel-tabs {
  cursor: pointer;
  margin-top: 0 !important;
}

.side-panel-tabs div {
  padding: 0;

}

.side-panel-tabs {
  padding-left: 0;
  display: flex;
  list-style-type: none;
  /* listStyleType: none; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* flexDirection: column;
  justifyContent: center;
  alignItems: flex-start; */
}

.react-tabs__tab {
  display: flex;
  height: 48px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid #E7E9EB;
  margin: 0 !important;
}

.side-panel-tab-button {
  color: var(--Typography-Body, #586673);
  font-feature-settings: 'liga' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}



.react-tabs__tab--selected {
  border-bottom: 1px solid #1782CA;
  background: #F3F9FC
}

.react-tabs__tab--selected .side-panel-tab-button {
  color: var(--Primary-500-Main, #1782CA);

}

.MuiStack-root.ra-field {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.RaLabeled-label {
  width: 160px;
}

.side-panel .RaLabeled-label span {
  color: #A0A8AF !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.side-panel .MuiTypography-root.MuiTypography-body2 {
  color: #000 !important;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}