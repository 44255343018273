.dashboard-container {
    min-height: 100%;
    background: #F5F5F6;
    flex-direction: row;
}

.dt {
    margin-right: 8px !important;
}

.dashboard-tools-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-direction: row;
}

.dashboard-wg-container {
    flex: 1;
    column-gap: 16px;
    display: flex;
    flex-direction: row;
}