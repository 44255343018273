.registration-wg-container {
    flex: 1;
    max-height: 310px;
    border-radius: 8px;
    padding: 12px;
    background-color: #FFF;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
}

.registration-wg-label {
    margin: 0;
    color: #113453;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}



.registration-funnel {
    display: flex;
}