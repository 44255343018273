.form-container {
    flex-direction: column;
    flex: 1;
    display: flex;
  }
  
  .application-form-container{
    flex-direction: row;
    flex: 1;
    display: flex;
  }
  .input-field {
    flex: 1;
    column-gap: 24px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-right: 24px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(16, 24, 40, 0.06);
    width: 100%;
    align-items: stretch;
  }